<template>
  <div class="relative" :class="count ? 'active' : ''">
    <div class="redot" v-if="count">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7.5" cy="7.5" r="7.5" fill="#D92D20" />
      </svg>
    </div>
    <div class="livestream-redot" v-if="circle.is_live_stream === 2">Đang trực tiếp</div>
    <div class="flex flex-column item-w h-196px">
      <p class="fs-18 mb-2 max-line-2" :class="count ? 'txt-pri fw-700' : 'txt-grey-900 fw-500'">
        <span v-if="circle.type === CIRCLE_TYPE.LIVE" class="mr-2 align-bottom">
          <img :src="liveStream" :alt="circle.name" width="22" height="22" />
        </span>
        <span class="align-bottom leading-tight">{{ circle.name }}</span>
      </p>
      <p
        class="txt-grey-900 fs-14 mb-auto max-line-1"
        :class="count ? 'txt-pri' : 'txt-grey-900'"
      >{{ $t('fm_member',{number:circle.member_count || 0}) }}</p>
      <p
        class="txt-grey-900 fs-14 fw-400 mb-0 max-line-3"
        :class="count ? 'fw-500 txt-pri' : 'fw-400 txt-grey-900'"
      >{{ circle.description }}</p>
    </div>
  </div>
</template>

<script>
import liveStream from '../../assets/images/livestream.png'
import { CIRCLE_TYPE } from '../../utils/constants'

export default {
  name: 'CircleListItem',
  props: ['circle', 'loading', 'type', 'activeConversation'],
  data () {
    return {
      count: 0,
      liveStream,
      CIRCLE_TYPE
    }
  },
  watch: {
    activeConversation (a) {
      if (a) {
        this.getNewMessageCount()
        this.listener()
      }
    }
  },
  mounted () {
    this.getNewMessageCount()
    this.listener()
  },
  methods: {
    listener () {
      if (
        this.activeConversation &&
        Object.keys(this.activeConversation).length
      ) {
        this.activeConversation.on('messageAdded', this.getNewMessageCount)
      }
    },
    async getNewMessageCount () {
      if (
        this.activeConversation &&
        Object.keys(this.activeConversation).length
      ) {
        this.count =
          (await this.activeConversation.getUnreadMessagesCount()) || 0
      }
    }
  }
}
</script>

<style scoped>
.item-w {
  padding: 1.3rem;
  background-color: #ffffff;
  border: 1px solid #edeae9;
  border-radius: 16px;
  cursor: pointer;
}

.mb-24 {
  margin-bottom: 24px;
}
.h-40px {
  height: 40px;
}
.h-196px {
  height: 196px;
}
.active {
  position: relative;
}
.redot {
  top: 10px;
  right: 10px;
  position: absolute;
}
.livestream-redot {
  background-color: #d92d20;
  color: white;
  padding: 1px 8px;
  top: 5px;
  left: 6px;
  position: absolute;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
}
</style>

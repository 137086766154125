<template>
  <div>
    <div class="item-w">
      <p class="txt-pri fs-18 fw-700 max-line-1 mb-16">{{ circle.name }}</p>
      <p class="txt-pri fs-14 fw-500 max-line-2 mb-24">{{ circle.description }}</p>
      <div class="row" v-if="type === 'pending'">
        <div class="col-6">
          <button
            class="btn fw-400 txt-pri btn--babyblue w-100"
            @click="rejectCircle(circle.id, $event)"
            :disabled="loading"
          >{{ $t("circle_feature.lbl_decline") }}</button>
        </div>
        <div class="col-6">
          <button
            class="btn fw-400 c-btn bg-pri bd-pri text-white w-100"
            @click="acceptCircle(circle, $event)"
            :disabled="loading"
          >{{ $t("circle_feature.lbl_accept") }}</button>
        </div>
      </div>
      <div class="row" v-if="type === 'waiting'">
        <div class="col-6 offset-6">
          <button
            class="btn fw-400 txt-pri btn--babyblue w-100 c-text"
            @click="rejectCircle(circle.id, $event)"
            :disabled="loading"
          >{{ $t("circle_feature.lbl_recall") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CIRCLE_INVITE_TYPE } from '../../utils/constants'

export default {
  name: 'CircleIPendingtem',
  props: ['circle', 'loading', 'type'],
  data () {
    return {
      CIRCLE_INVITE_TYPE
    }
  },
  methods: {
    rejectCircle (id, e) {
      e.stopPropagation()
      this.$emit('rejectCircle', id, this.type)
    },
    acceptCircle (circle, e) {
      e.stopPropagation()
      this.$emit('acceptCircle', circle)
    }
  }
}
</script>

<style scoped>
.item-w {
  padding: 1.3rem;
  background-color: #ffffff;
  border: 1px solid #edeae9;
  border-radius: 16px;
  cursor: pointer;
}

.mb-24 {
  margin-bottom: 24px;
}
</style>

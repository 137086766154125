<template>
  <div v-loading="loading">
    <div class="member-wraper">
      <!-- <div class="border-bottom mb-3">
          <span
            class="mb-0 txt-pri bd-pri robo-14-500"
            style="border-bottom: 3px solid"
            >Mời thành viên tham gia</span
          >
      </div>-->
      <!-- <div class="mb-3">
          <p class="mb-2 robo-14-500">Thành viên định kỳ</p>
      </div>-->
      <div class="mb-3">
        <div class="input-group border radius-10">
          <input type="text" class="form-control bg-white radius-10" v-model="search" @keyup.enter="selectEmail()"
            :placeholder="$t('lbl_enter_email')" />
          <span class="input-group-text bg-white border-0 radius-10 px-3 cursor-pointer" @click="selectEmail()">
            <span class="fs-20 fw-700">+</span>
          </span>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center mb-2" href="javascript:;" v-for="(user, i) in members"
          :key="i + 'selected-mem'">
          <span v-if="user && user.avatar" class="img-avatar" :style="'background-image: url(' + getUrlImage(user.avatar) + ');'
    "></span>
          <span v-if="user && !user.avatar" class="img-avatar">
            {{
    user.name ? user.name.charAt(0) : user.email ? user.email.charAt(0) : ""
  }}
          </span>
          <span class="list-item-name">
            <span class="d-block mb-0 robo-14-400">{{ user && user.name }}</span>
            <span class="robo-12-400" v-if="user.id">
              {{
    user && user.email
  }}
            </span>
          </span>
          <span class="cursor-pointer" @click="deselectDr(user)">x</span>
        </div>
      </div>
      <button class="btn bg-pri bd-pri text-white save-btn" :disabled="loading || (!members || !members.length)"
        type="button" @click="onSubmit">{{ $t('lbl_send_invitation') }}</button>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'CircleAddMember',
  props: ['circle'],
  data () {
    return {
      members: [],
      search: '',
      loading: false
    }
  },
  computed: {},
  mounted () { },
  methods: {
    onSubmit () {
      if (this.circle) {
        this.addCircleMembers(this.circle.id)
      }
    },
    async addCircleMembers (id) {
      const self = this
      if (this.members.length) {
        self.loading = true
        const members = []
        this.members.forEach(c => {
          members.push({ email: c.email })
        })
        await this.$rf
          .getRequest('DoctorRequest')
          .addCircleMembers(id, { members: members })
          .then(r => {
            self.finish()
          })
          .catch(e => {
            console.log(e)
            self.loading = false
          })
      } else {
        this.finish()
      }
    },
    getUrlImage (path) {
      return appUtils.getImageURL(path)
    },
    finish () {
      this.members = []
      this.$emit('refresh')
    },
    async selectEmail () {
      if (this.search) {
        if (!this.validateEmail(this.search)) {
          alert(this.$t('lbl_please_enter_the_correct_email_format'))
          return
        }

        await this.showUserByEmail(this.search)
          .then(res => {
            let user = {
              id: null,
              email: this.search,
              name: this.search,
              avatar: null,
              qr_code: null
            }
            // user = res?.data || null
            user = res?.data ? {
              ...user,
              ...res?.data
            } : user
            if (!this.members.find(mem => mem.email === user?.email)) {
              this.members.push(user)
            }
            setTimeout(() => {
              this.search = ''
            }, 50)
          })
          .catch(e => {
            if (e.response.data.code === 500001) {
              this.$toast.open({
                message: 'Không tồn tại người dùng sử dụng Email!',
                type: 'warning'
              })
            } else {
              this.$toast.open({
                type: 'error',
                message: this.$t('Có lỗi xảy ra. Vui lòng thử lại sau.')
              })
            }
            return e
          })
      }
    },
    async showUserByEmail (email) {
      let params = {
        email: email
      }
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorByEmail(params)
      return r
    },
    selectDr (dr) {
      this.search = ''
      const user = dr.user
      if (!this.members.find(u => u.email === user?.email)) {
        this.members.push(user)
      }
    },
    deselectDr (user) {
      const indexUser = this.members.findIndex(u => u.email === user?.email)
      if (indexUser > -1) {
        this.members.splice(indexUser, 1)
      }
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  }
}
</script>

<style scoped>
.member-wraper {
  width: 100%;
  padding: 10px;
  min-height: 250px;
}

.save-btn {
  right: 10px;
  bottom: 10px;
  position: absolute;
}

.box-member {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 10px;
}

.img-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #20419b;
  background-position: center;
  background-size: 100%;
}

.list-item-name {
  width: calc(100% - 60px);
}

.list-item-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dropdown-list-m {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

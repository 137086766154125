<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalAddCircle"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAddCircleTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between p-3">
          <h5 class="modal-title" id="modalUpdateDiseaseLabel">
            {{ $t("circle_feature.lbl_add_circle") }}
          </h5>
          <button
            type="button"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="robo-18-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label class="font-medium text-black required mb-2">
            {{ $t("circle_feature.lbl_you_want") }}
          </label>
          <div class="row mb-3">
            <div class="col">
              <label
                for="o1"
                class="fs-16 cursor-pointer"
                :class="type == 'create' ? 'txt-grey-900' : 'txt-grey-600'"
              >
                <input type="radio" id="o1" v-model="type" value="create" />
                {{ $t("circle_feature.btn_create_circle") }}
              </label>
            </div>
            <!-- <div class="col-5">
              <label
                for="create-live-stream-circle"
                class="fs-16 cursor-pointer"
                :class="type == 'create-live' ? 'txt-grey-900' : 'txt-grey-600'"
              >
                <input
                  type="radio"
                  id="create-live-stream-circle"
                  v-model="type"
                  value="create-live"
                />
                {{ $t("circle_feature.btn_create_live_circle") }}
              </label>
            </div>-->
            <div class="col-4">
              <label
                for="o2"
                class="fs-16 cursor-pointer"
                :class="type == 'join' ? 'txt-grey-900' : 'txt-grey-600'"
              >
                <input type="radio" id="o2" v-model="type" value="join" />
                {{ $t("circle_feature.lbl_join_circle") }}
              </label>
            </div>
          </div>

          <div v-if="type === 'join'">
            <label class="font-medium text-black required mb-2">
              {{ $t("circle_feature.lbl_circle_code") }}
            </label>
            <input
              type="text"
              class="form-control bg-white border mb-3"
              v-model="code"
              :placeholder="$t('lbl_enter_code_circle')"
              @input="typeCode"
            />
            <div v-if="(!circle || !circle.code) && code">
              <p>
                <img
                  class="mr-2"
                  src="../../../public/assets/images/Exclude.svg"
                  alt
                />
                <span class="text-danger fs-14 fw-400">{{
                  $t("lbl_no_circle_is_found__please_re_check_your_circle_code")
                }}</span>
              </p>
            </div>
            <div>
              <div
                class="mb-2 p-3 border radius-10"
                v-for="c in circles.data"
                :key="c.id"
                :class="circle && circle.id === c.id ? 'bd-pri' : ''"
                @click="selectCircle(c)"
              >
                <p class="fs-18 fw-700">{{ c.name }}</p>
                <p class="fs-14 fw-500">{{ c.description }}</p>
                <div class="d-flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                  >
                    <path
                      d="M12.5947 18.2565V13.6353M12.5947 12.6891C12.8067 10.038 11.3857 5.02198 4.00492 6.16728C3.89887 8.34122 5.46835 12.6891 12.5947 12.6891ZM12.5947 12.6891C13.7081 11.5226 15.723 8.56014 20.5 10C20.0654 12.0378 18.3162 14.7488 12.5947 13.6353M12.5947 12.6891V13.6353"
                      stroke="#84818A"
                      stroke-width="2"
                    />
                    <rect x="3" y="18" width="17" height="2" fill="#84818A" />
                  </svg>
                  <div>
                    <p class="fs-14 fw-500 mb-1 mt-1">Seed</p>
                    <div class="d-flex align-items-center">
                      <div style="min-width: 40px">
                        <span
                          v-if="c.seed && c.seed.avatar"
                          class="avatar avatar-sm avatar-rounded"
                          :style="
                            'background-image: url(' +
                            getImageURL(c.seed && c.seed.avatar) +
                            ')'
                          "
                        ></span>
                        <span
                          v-if="c.seed && !c.seed.avatar"
                          class="avatar avatar-sm avatar-rounded bg-pri text-white"
                          >{{ (c.seed && c.seed.name.charAt(0)) || "-" }}</span
                        >
                      </div>

                      <div>
                        <p class="fs-14 fw-600 txt-black mb-1">
                          {{ c.seed && c.seed.name }}
                        </p>
                        <p class="fs-12 fw-500 txt-grey-600 mb-1">
                          {{ c.seed && c.seed.doctor && c.seed.doctor.chuc_vu }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <label class="font-medium text-black required mb-2">
              {{ $t("circle_feature.lbl_circle_name") }}
            </label>
            <input
              type="text"
              class="form-control bg-white border mb-3"
              v-model="name"
              :placeholder="$t('circle_feature.lbl_enter_name_msg')"
            />
            <label class="font-medium text-black mb-2">
              {{ $t("circle_feature.lbl_description") }}
            </label>
            <textarea
              class="form-control bg-white border mb-3"
              rows="2"
              v-model="description"
            ></textarea>
            <div class="d-flex items-center mb-3">
              <div class="mr-3" v-if="logo">
                <img class="w-16 h-16 object-contain" :src="logo" :alt="name" />
              </div>
              <div class="w-full">
                <div class="mb-1 font-medium text-black">
                  {{ $t("lbl_upload_logo_circle") }}
                </div>
                <input
                  id="circle-logo-upload"
                  class="form-control bg-white border mb-0"
                  name="circle-logo-upload"
                  type="file"
                  accept="image/*"
                  @change="(e) => onSelectFile(e, 'logo')"
                />
              </div>
            </div>
            <div class="d-flex items-center mb-3">
              <div class="mr-3" v-if="background">
                <img
                  class="w-16 h-16 object-contain"
                  :src="background"
                  :alt="name"
                />
              </div>
              <div class="w-full">
                <div class="mb-1 font-medium text-black">
                  {{ $t("lbl_upload_background_photo") }}
                </div>
                <input
                  id="circle-background-upload"
                  class="form-control bg-white border mb-0"
                  name="circle-background-upload"
                  type="file"
                  accept="image/*"
                  @change="(e) => onSelectFile(e, 'background')"
                />
              </div>
            </div>
            <div class="flex items-center">
              <label
                for="circle_type_1"
                class="fs-16 cursor-pointer txt-grey-900 mr-4"
              >
                <input
                  type="radio"
                  id="circle_type_1"
                  v-model="circle_type"
                  value="create"
                />
                {{ $t("lbl_for_doctors__healthcare_professionals") }}
              </label>
              <label
                for="circle_type_2"
                class="fs-16 cursor-pointer txt-grey-900"
              >
                <input
                  type="radio"
                  id="circle_type_2"
                  v-model="circle_type"
                  value="create-live"
                />
                {{ $t("lbl_for_patients") }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary w-100 fw-400"
            v-if="type !== 'join'"
            :disabled="loading"
            @click="createCircle"
          >
            {{ $t("circle_feature.lbl_add") }}
          </button>
          <button
            type="button"
            class="btn btn-primary w-100 fw-400"
            v-else
            :disabled="!code || loading || !circle"
            @click="joinCircle"
          >
            {{ $t("circle_feature.lbl_request_join") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { CIRCLE_TYPE } from '../../utils/constants'
export default {
  name: 'AddCircle',
  props: ['add_type'],
  data () {
    return {
      type: 'create', // Types: create, join
      circle_type: 'create', // Circle type: create (1), create-live (2)
      code: '',
      description: '',
      name: '',
      logo: null,
      background: null,
      loading: false,
      circle: null,
      circles: [],
      randomCode: appUtils.randomStr(9, true)
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modalAddCircle').on('shown.bs.modal', function (e) {
        self.type = self.add_type || 'create'
      })
      window.$('#modalAddCircle').on('hidden.bs.modal', function (e) {
        self.refresh(true)

        window.$('#circle-logo-upload')[0].value = ''
        window.$('#circle-background-upload')[0].value = ''
      })
    })
  },
  methods: {
    async createCircle () {
      if (!this.name) {
        alert('Vui lòng nhập tên Circle')
        return
      }
      if (this.loading) return
      let self = this
      this.loading = true
      let params = {
        name: this.name,
        description: this.description,
        logo: this.logo,
        background: this.background
      }

      if (this.type === 'create' && this.circle_type === 'create-live') {
        params.type = CIRCLE_TYPE.LIVE
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .createLiveCircle(params)
          .then(async (result) => {
            await this.createConferenceRoom(result.data.id)
            self.$reloadChat++
            self.refresh()
            return result
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            self.loading = false
          })
        console.log(res)
        return
      }

      params.type = CIRCLE_TYPE.NORMAL
      // console.log(params)

      await this.$rf
        .getRequest('DoctorRequest')
        .createCircle(params)
        .then(async (result) => {
          await this.createConferenceRoom(result.data.id)
          await this.createLiveRoom(result.data.id)
          self.$reloadChat++
          self.refresh()
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          self.loading = false
        })
    },
    async createConferenceRoom (id) {
      await this.$rf.getRequest('DoctorRequest').createConferenceRoom(id)
    },
    async createLiveRoom (id) {
      await this.$rf.getRequest('DoctorRequest').createLiveRoom(id)
    },
    typeCode () {
      console.log(this.code.length)
      if (this.code.length === 8) {
        this.getCircles()
      } else {
        this.circles = []
        this.circle = null
      }
    },
    async getCircles () {
      let self = this
      let params = {
        code: this.code
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getCircles(params)
        .then((result) => {
          self.circles = result?.data
          self.circle = result?.data?.data[0]
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          self.loading = false
        })
    },
    async joinCircle () {
      let self = this
      if (this.loading) return
      if (!this.circle) return
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .joinCircle(this.circle.id)
        .then((result) => {
          self.refresh()
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          self.loading = false
        })
    },
    selectCircle (c) {
      this.circle = c
    },
    getImageURL (a) {
      return appUtils.getImageURL(a)
    },
    refresh (isReset) {
      this.type = 'create'
      this.circle_type = 'create'
      this.code = ''
      this.description = ''
      this.name = ''
      this.circle = null
      this.circles = []
      this.text = ''
      this.logo = ''
      this.background = ''
      this.randomCode = appUtils.randomStr(9, true)

      if (!isReset) {
        this.$toast.open({
          message: 'Thành công',
          type: 'success'
        })
        this.$emit('refresh')
      }
    },
    async uploadFiles (file, id) {
      const params = {
        file,
        FolderTarget: `circle`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async onSelectFile (event, targetKey) {
      const res = await this.uploadFiles(
        event.target.files[0],
        Number(this.randomCode)
      )
      this[targetKey] = res
    }
  }
}
</script>

<style scoped>
.btn:disabled {
  background-color: #98a2b3;
}
</style>

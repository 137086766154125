<template>
  <div>
    <div class="member-wraper" v-loading="loading">
      <div class="mb-3">
        <div class="input-group border radius-10">
          <input
            type="text"
            class="form-control bg-white radius-10"
            v-model="search"
            @input="handleChangeSearch()"
            :placeholder="$t(`circle_feature.lbl_search_patient`)"
          />
        </div>
      </div>
      <div
        v-if="paginationProps && paginationProps.total"
        class="d-flex align-items-center mb-2 checkbox-wrapper-1"
        href="javascript:;"
      >
        <input
          v-model="selectAll"
          id="selectAll"
          name="selectAll"
          type="checkbox"
          class="substituted"
          aria-hidden="true"
          @change="toggleSelectAll"
        />
        <label for="selectAll" class="d-flex align-items-center">
          <b class="mr-2">{{ $t(`circle_feature.lbl_select_all_patient`) }}</b>
          <span v-if="selectedUserIds.length">
            ({{ $t(`circle_feature.lbl_selected_number`) }}:
            {{ selectedUserIds.length }}/{{ paginationProps.total }})
          </span>
        </label>
      </div>
      <div v-if="paginationProps.total && members.length" class="mb-3">
        <div
          class="d-flex align-items-center mb-2 checkbox-wrapper-1"
          href="javascript:;"
          v-for="(user, i) in members"
          :key="i + 'selected-mem'"
        >
          <input
            v-model="selectedUserIds"
            :id="user && user.user && user.user.id"
            :value="user.user_id"
            :name="user.user_id"
            type="checkbox"
            class="substituted"
            aria-hidden="true"
          />
          <label :for="user.user_id" class="d-flex align-items-center">
            <span
              v-if="user && user.user && user.user.avatar"
              class="img-avatar"
              :style="{
                'background-image':
                  'url(' + getUrlImage(user.user.avatar) + ')',
              }"
            ></span>
            <span v-if="user && user.user && !user.user.avatar" class="img-avatar">
              {{
              user.name
              ? user.name.charAt(0)
              : user && user.user && user.user.email
              ? user && user.user && user.user.email.charAt(0)
              : ""
              }}
            </span>
            <span class="list-item-name">
              <span class="d-block mb-0 robo-14-400">
                {{
                user && user.name
                }}
              </span>
              <span class="robo-12-400" v-if="user && user.user && user.user.id">
                {{
                user && user.user && user.user.email
                }}
              </span>
            </span>
          </label>
          <!-- <span class="cursor-pointer" @click="deselectDr(user)">x</span> -->
        </div>
        <AppPagination
          :paginationProps="{
            currentPage: params.page_num,
            totalItems: paginationProps.total,
            pageSize: params.page_size,
          }"
          @onChangePagination="(data) => handleChangePagination(data)"
        />
      </div>
      <div v-else>
        <p class="fs-16 fw-500">{{ $t("circle_feature.lbl_no_patient_left") }}</p>
      </div>
      <button
        class="btn bg-pri bd-pri text-white save-btn"
        :disabled="
          loading || !members || !members.length || !selectedUserIds.length
        "
        type="button"
        @click="onSubmit"
      >{{ $t("circle_feature.lbl_send_invitation") }}</button>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import appUtils from '../../utils/appUtils'
import { AppPagination } from '@/components/Common'

export default {
  name: 'CircleInvitePatient',
  components: { AppPagination },
  props: ['circle'],
  data () {
    return {
      members: [],
      selectedUserIds: [],
      selectAll: false,
      search: '',
      params: {
        page_num: 1,
        page_size: 10
      },
      loading: false,
      paginationProps: {
        current_page: 0,
        last_page: 0,
        per_page: 10,
        total: 0
      }
    }
  },
  created () {
    this.getListMemberNotInvited()
  },
  watch: {
    selectedUserIds () {
      if (this.paginationProps.total !== this.selectedUserIds.length) {
        this.selectAll = false
      } else this.selectAll = true
    }
  },
  methods: {
    async getListMemberNotInvited () {
      this.loading = true
      let self = this
      await this.$rf
        .getRequest('DoctorRequest')
        .getListPatientNotInCircle({
          ...this.params,
          keyword: this.search,
          circle_id: this.circle.id
        })
        .then(r => {
          this.members = r.data.data
          this.paginationProps.total = r.data.meta.total
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
        })
        .finally(() => {
          self.loading = false
        })
    },
    async toggleSelectAll () {
      if (this.selectAll) {
        this.loading = true
        let self = this
        await this.$rf
          .getRequest('DoctorRequest')
          .getListPatientIdsNotInCircle({
            circle_id: this.circle.id
          })
          .then(r => {
            this.selectedUserIds = r.data.data.map(mem => mem.user_id)
          })
          .catch(e => {
            self.$toast.open({
              message: this.$t('circle_feature.lbl_cannot_get_patient_list'),
              type: 'warning'
            })
          })
          .finally(() => {
            self.loading = false
          })
      } else {
        this.selectedUserIds = []
      }
    },
    onSubmit () {
      if (this.circle) {
        this.invitePatientToJoinCircle()
      }
    },
    async invitePatientToJoinCircle () {
      const self = this
      self.loading = true
      if (this.selectedUserIds) {
        await this.$rf
          .getRequest('DoctorRequest')
          .invitePatientToJoinCircle({
            circle_id: this.circle.id,
            user_ids: this.selectedUserIds
          })
          .then(r => {
            self.finish()
            self.loading = false
            self.$toast.open({
              message: this.$t('workspace_setting.lbl_msg_success'),
              type: 'success'
            })
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        self.loading = false
        this.finish()
      }
    },
    async handleChangePagination (data) {
      if (data.currentPage) this.params.page_num = data.currentPage
      if (data.pageSize) this.params.page_size = data.pageSize
      await this.getListMemberNotInvited()
    },
    getUrlImage (path) {
      return appUtils.getImageURL(path)
    },
    finish () {
      this.selectAll = false
      this.selectedUserIds = []
      this.getListMemberNotInvited()
      this.$emit('refresh')
    },
    async showUserByEmail (email) {
      let params = {
        email: email
      }
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .showUserByEmail(params)
      return r
    },
    selectDr (dr) {
      this.search = ''
      const user = dr.user
      if (!this.members.find(u => u.email === user?.email)) {
        this.members.push(user)
      }
    },
    deselectDr (user) {
      const indexUser = this.members.findIndex(u => u.email === user?.email)
      if (indexUser > -1) {
        this.members.splice(indexUser, 1)
      }
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    handleChangeSearch: debounce(function () {
      this.getListMemberNotInvited()
    }, 500)
  }
}
</script>

<style scoped>
.member-wraper {
  width: 100%;
  padding: 30px;
  padding-bottom: 40px;
  min-height: 250px;
}
.save-btn {
  right: 10px;
  bottom: 10px;
  position: absolute;
}
.box-member {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 10px;
}

.img-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #20419b no-repeat center/cover;
}

.list-item-name {
  width: calc(100% - 60px);
}

.list-item-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.dropdown-list-m {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>


<style>
.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}
.checkbox-wrapper-1 [type="checkbox"].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox-wrapper-1 [type="checkbox"].substituted + label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset,
    0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
      no-repeat center,
    white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}
.checkbox-wrapper-1 [type="checkbox"].substituted:enabled:active + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:enabled
  + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}
.checkbox-wrapper-1 [type="checkbox"].substituted:checked + label:before {
  background-color: #3b99fc;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled
  + label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55),
    0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:focus:active + label:before,
.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55),
    0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:disabled + label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type="checkbox"].substituted.dark + label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled
  + label:active:before {
  background-color: #444;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
}
.checkbox-wrapper-1 [type="checkbox"].substituted.dark:checked + label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled
  + label:active:before {
  background-color: #c68035;
}
</style>

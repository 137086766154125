<template>
  <div
    class="modal fade"
    id="modalCreateSchedule"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalCreateLivestreamSchedule"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between p-3">
          <h5
            class="modal-title"
            id="ModalCreateLivestreamScheduleTitle"
          >{{ $t(`circle_feature.btn_add_schedule`) }}</h5>
          <button
            type="button"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="robo-18-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <label
                class="font-medium text-black required mb-2"
              >{{ $t("circle_feature.lbl_live_at") }}</label>
            </div>
            <div class="col-6">
              <label
                class="font-medium text-black mb-2"
              >{{ $t("circle_feature.lbl_estimate_duration") }}</label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <el-date-picker
                v-model="form.schedule_date"
                format="dd-MM-yyyy HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                align="right"
                type="datetime"
                placeholder="DD-MM-YYYY HH:MM:SS"
                class="form--input txt-grey-900 fs-16"
              ></el-date-picker>
            </div>
            <div class="col-6">
              <el-time-select
                v-model="form.duration"
                :picker-options="{
                  start: '00:30',
                  step: '00:30',
                  end: '08:00'
                }"
                :placeholder="$t('circle_feature.lbl_select_estimate_duration')"
                class="form--input txt-grey-900 fs-16"
              ></el-time-select>
            </div>
          </div>

          <div>
            <label
              class="font-medium text-black required mb-2"
            >{{ $t("circle_feature.lbl_livestream_title") }}</label>
            <input
              type="text"
              class="form-control bg-white border mb-3"
              v-model="form.title"
              :placeholder="$t('circle_feature.lbl_enter_livestream_title')"
            />

            <label class="font-medium text-black mb-2">{{ $t("circle_feature.lbl_description") }}</label>
            <textarea class="form-control bg-white border mb-3" rows="2" v-model="form.content"></textarea>

            <label class="font-medium text-black mb-2">{{ $t("circle_feature.lbl_presenter") }}</label>
            <el-select
              class="form--input txt-grey-900 fs-16 mb-3"
              v-model="form.presenter_ids"
              multiple
              :placeholder="$t('circle_feature.lbl_set_specialist_as_presenter')"
            >
              <el-option
                v-for="item in specialists"
                :key="item.id"
                :label="`${item.user_info.name} ${checkIsYou(item) ? '(Bạn)' : ''}`"
                :value="item.user_id"
              ></el-option>
            </el-select>
          </div>

          <label class="font-medium text-black required mb-2">
            {{
            $t("circle_feature.lbl_schedule_status")
            }}
          </label>
          <div class="row">
            <div class="col-4">
              <label for="o1" class="fs-16 cursor-pointer txt-grey-900">
                <input type="radio" id="o1" v-model="form.status" value="1" />
                {{ $t("circle_feature.lbl_draft_schedule") }}
              </label>
            </div>
            <div class="col-4">
              <label for="o2" class="fs-16 cursor-pointer txt-grey-900">
                <input type="radio" id="o2" v-model="form.status" value="2" />
                {{ $t("circle_feature.lbl_public_schedule") }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary text-white fw-400"
            :disabled="loading"
            @click="handleSubmit"
          >{{ isEdit ? $t("button.update") : $t("button.create") }}</button>
          <button
            type="button"
            class="btn btn-primary text-white fw-400"
            :disabled="loading"
            data-dismiss="modal"
          >{{ $t("button.cancel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalCreateLivestreamSchedule',
  props: ['scheduleSelected', 'specialists'],
  data () {
    return {
      form: {
        circle_id: 0,
        schedule_date: '',
        duration: 0,
        title: '',
        content: '',
        status: 1,
        presenter_ids: []
      },
      loading: false
    }
  },
  computed: {
    isEdit () {
      return !!this.scheduleSelected.id
    },
    convertHHMMToDuration () {
      let [h, m] = this.form?.duration?.split(':')
      return +h * 60 + +m
    },
    scheduleDateEpochTimestamp () {
      return window
        .moment(this.form.schedule_date, 'YYYY-MM-DD HH:mm:ss')
        .valueOf()
    },
    scheduleSelectedPresenterIds () {
      return this.scheduleSelected?.circle_schedule_speakers
        ?.map(speaker => {
          if (
            this.specialists
              .map(specialist => specialist.user_id)
              .includes(speaker.user_id)
          ) {
            return speaker.user_id
          }
        })
        .filter(id => !!id)
    },
    isChangePresenterList () {
      if (
        this.form.presenter_ids?.length !==
        this.scheduleSelectedPresenterIds?.length
      ) {
        return true
      }
      let isChanged = false
      this.form.presenter_ids.forEach(id => {
        if (!this.scheduleSelectedPresenterIds?.includes(id)) isChanged = true
      })
      return isChanged
    }
  },
  watch: {
    scheduleSelected: {
      handler () {
        this.form = {
          schedule_date: this.scheduleSelected.schedule_date,
          duration: this.scheduleSelected.duration,
          title: this.scheduleSelected.title,
          content: this.scheduleSelected.content,
          status: +this.scheduleSelected.status,
          circle_id: +this.$route.params.id,
          presenter_ids: this.scheduleSelectedPresenterIds
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async createNewSchedule () {
      await this.$rf
        .getRequest('CommonRequest')
        .createLivestreamSchedule({
          ...this.form,
          duration: this.convertHHMMToDuration,
          schedule_date: this.scheduleDateEpochTimestamp,
          status: +this.form.status
        })
        .then(res => {
          this.$emit('hideModal')
          this.$toast.open({
            message: 'Tạo lịch phát sóng thành công',
            type: 'success'
          })
        })
        .catch(err => {
          this.$toast.open({
            message: 'Tạo lịch phát sóng thất bại',
            type: 'error'
          })
          console.log(err)
        })
    },
    async editSchedule () {
      await this.$rf
        .getRequest('CommonRequest')
        .editLivestreamSchedule(this.scheduleSelected.id, {
          ...this.form,
          duration: this.convertHHMMToDuration,
          schedule_date: this.scheduleDateEpochTimestamp,
          status: +this.form.status
        })
        .then(res => {
          this.$toast.open({
            message: 'Thành công',
            type: 'success'
          })
          this.$emit('hideModal')
        })
        .catch(err => {
          this.$toast.open({
            message: 'Thất bại',
            type: 'error'
          })
          console.log(err)
        })
    },
    handleSubmit () {
      // LINHNT: Clear presenter_ids support for Conference
      // if (!this.form.title || !this.form.schedule_date || !this.form.presenter_ids.length || !this.form.status) {
      if (!this.form.title || !this.form.schedule_date || !this.form.status) {
        alert('Các mục có dấu * không được để trống')
        return
      }
      if (this.scheduleDateEpochTimestamp <= new Date().getTime()) {
        alert('Lịch phát phải bắt đầu sau thời điểm hiện tại')
        return
      }
      if (this.isEdit) {
        if (!this.isChangePresenterList) {
          this.form.presenter_ids = []
        }
        this.editSchedule()
      } else this.createNewSchedule()
    },
    checkIsYou (member) {
      return member?.user_id === this.$user?.id
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  color: black;
}
.btn:disabled {
  background-color: #98a2b3;
}
</style>>

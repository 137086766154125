<template>
  <div>
    <div class="member-wraper" v-loading="loading">
      <div v-if="members.data && members.data.length" class="mb-3">
        <template
          v-if="((selectedMembers && selectedMembers.length) || isOpenAssignSection) && !loading"
        >
          <p
            v-if="(!selectedMembers || !selectedMembers.length)"
            class="fs-16 fw-500"
          >{{ circle && (circle.type === 2 || selectedFeature === 'livestream' || (circle.live_room_id && !conferenceRoomStatus)) ? "Chưa phân công vai trò trong buổi phát trực tiếp" : "Chưa phân công thông dịch ngôn ngữ. Vui lòng chọn lựa bác sĩ/chuyên gia trong circle để phân công vai trò thông dịch" }}</p>
          <div v-else class="overflow-y-auto overflow-x-hidden" :style="{maxHeight: '400px'}">
            <div
              class="d-flex align-items-center mb-2 checkbox-wrapper-1 justify-between"
              href="javascript:;"
              v-for="(member, i) in selectedMembers"
              :key="i + 'selected-mem'"
            >
              <p class="d-flex align-items-center mb-0 w-2/3">
                <span
                  v-if="member && member.user.avatar"
                  class="img-avatar"
                  :style="{
                  'background-image':
                    'url(' + getUrlImage(member.user.avatar) + ')',
                }"
                ></span>
                <span v-if="member && !member.user.avatar" class="img-avatar">
                  {{
                  member.user.name
                  ? member.user.name.charAt(0)
                  : member.user.email
                  ? member.user.email.charAt(0)
                  : ""
                  }}
                </span>
                <span class="list-item-name flex-1">
                  <span
                    class="mb-0"
                    :class="member && member.user_id === $user.id ? 'robo-14-700' : 'robo-14-400'"
                  >
                    {{
                    `${member && member.user_id === $user.id ? `(${$t('lbl_you')}) ` : ''}${member && member.user.name}`
                    }}
                  </span>
                  <span class="robo-12-400" v-if="member.user.id">
                    {{
                    member && member.user && member.user.doctor && member.user.doctor.chuc_vu
                    }}
                  </span>
                </span>
              </p>
              <el-select
                :value="getMemberRoleName(member.one_hundred_ms_role)"
                class="w-50"
                @change="(role_name) => {assignMember(member.id, role_name)}"
                :disabled="!isAdmin && isMyHasAssignRole"
              >
                <el-option
                  v-for="role in roles"
                  :key="role.role_id"
                  :value="role.name"
                  :label="role.name_lang"
                ></el-option>
              </el-select>
              <button
                data-v-72b4a568
                v-if="isAdmin"
                type="button"
                class="btn btn-FEF3F2 ml-2"
                @click="unassignMember(member.id)"
              >{{ $t('lbl_btn_delete') }}</button>
            </div>
          </div>
          <div v-if="isActiveDropdownAddMember && isAdmin" class="input-group border radius-10">
            <el-select
              ref="select_member"
              class="w-100"
              v-model="selectedCircleMemberIds"
              :placeholder="$t('lbl_enter_doctors__healthcare_professionals_name')"
              allow-create
              multiple
              filterable
            >
              <el-option
                v-for="member in allMembers"
                :key="member.user_id"
                :label="member.user && member.user.name"
                :value="member.id"
              ></el-option>
            </el-select>
            <button
              type="button"
              class="close border-0"
              aria-label="Close"
              @click="toggleDropdownAddMember"
            >
              <span class="robo-18-500">&times;</span>
            </button>
          </div>
          <div
            v-else-if="isAdmin"
            class="btn-plus w-100 mt-3 mb-3"
            @click="toggleDropdownAddMember"
            style="padding: 4px;"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 cursor-pointer w-100"
            >
              <path
                opacity="0.1"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.84 20.1595H27.72C28.1839 20.1595 28.56 20.5355 28.56 20.9995C28.56 21.4634 28.1839 21.8395 27.72 21.8395H21.84V27.7195C21.84 28.1834 21.4639 28.5595 21 28.5595C20.5361 28.5595 20.16 28.1834 20.16 27.7195V21.8395H14.28C13.8161 21.8395 13.44 21.4634 13.44 20.9995C13.44 20.5355 13.8161 20.1595 14.28 20.1595H20.16V14.2795C20.16 13.8155 20.5361 13.4395 21 13.4395C21.4639 13.4395 21.84 13.8155 21.84 14.2795V20.1595Z"
                fill="#20419B"
              />
            </svg>
          </div>
        </template>
        <template v-else-if="isAdmin">
          <p
            class="fs-16 fw-500"
          >{{ circle && (circle.type === 2 || selectedFeature === 'livestream' || (circle.live_room_id && !conferenceRoomStatus)) ? "Chưa phân công vai trò trong buổi phát trực tiếp" : "Chưa phân công thông dịch ngôn ngữ. Vui lòng chọn lựa bác sĩ/chuyên gia trong circle để phân công vai trò thông dịch" }}</p>
          <div class="btn-plus w-100 mt-3 mb-3" @click="openAssignSection">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 cursor-pointer w-100"
            >
              <path
                opacity="0.1"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.84 20.1595H27.72C28.1839 20.1595 28.56 20.5355 28.56 20.9995C28.56 21.4634 28.1839 21.8395 27.72 21.8395H21.84V27.7195C21.84 28.1834 21.4639 28.5595 21 28.5595C20.5361 28.5595 20.16 28.1834 20.16 27.7195V21.8395H14.28C13.8161 21.8395 13.44 21.4634 13.44 20.9995C13.44 20.5355 13.8161 20.1595 14.28 20.1595H20.16V14.2795C20.16 13.8155 20.5361 13.4395 21 13.4395C21.4639 13.4395 21.84 13.8155 21.84 14.2795V20.1595Z"
                fill="#20419B"
              />
            </svg>
          </div>
        </template>
        <!-- <AppPagination
          v-if="members.total >= page_size_default"
          :paginationProps="{
            currentPage: members.current_page,
            totalItems: members.total,
            pageSize: page_size_default,
          }"
          @onChangePagination="(data) => handleChangePagination(data)"
        />-->
      </div>
      <div v-else>
        <p class="fs-16 fw-500">{{ $t("circle_feature.lbl_no_patient_left") }}</p>
      </div>
      <!-- <button
        class="btn bg-pri bd-pri text-white save-btn"
        :disabled="loading || !selectedMembers || !selectedMembers.length"
        type="button"
        @click="onSubmit"
      >{{ circle && (circle.type === 2 || selectedFeature === 'livestream' || (circle.live_room_id && !conferenceRoomStatus)) ? circleMemberData && circleMemberData.one_hundred_ms_role === "broadcaster" ? $t("circle_feature.lbl_go_to_livestream_room") : $t("circle_feature.lbl_go_to_watch_livestream_room") : $t("circle_feature.lbl_go_to_conference") }}</button>-->
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { AppPagination } from '@/components/Common'

export default {
  name: 'CircleAssignRole',
  components: { AppPagination },
  props: [
    'circle',
    'circle_member',
    'isAdmin',
    'isMyHasAssignRole',
    'conferenceRoomStatus',
    'ctaRefreshRoles',
    'selectedFeature'
  ],
  data () {
    return {
      members: {
        count: 0,
        data: [],
        current_page: 1,
        per_page: 10,
        last_page: 0,
        total: 0
      },
      allMembers: [],
      selectedCircleMemberIds: [],
      page_size_default: 10,
      loading: false,
      roles: [],
      isOpenAssignSection: false,
      isActiveDropdownAddMember: false,
      circleMemberData: null
    }
  },
  watch: {
    circle_member: {
      handler (data) {
        if (data) {
          this.circleMemberData = { ...data }
        }
      },
      deep: true,
      immediate: true
    },
    ctaRefreshRoles: {
      handler () {
        // console.log('ctaRefreshRoles')
        // this.prepare()
      }
    }
    // selectedFeature: {
    //   async handler () {
    //     await this.prepare()
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  async mounted () {},
  computed: {
    selectedMembers () {
      const data = this.allMembers.length > 0 ? [...this.allMembers] : []
      const selectedMems = data?.filter(mem =>
        this.selectedCircleMemberIds.includes(mem.id)
      )
      return selectedMems
    }
  },
  async created () {},
  methods: {
    async prepare () {
      await Promise.all([
        this.getCircleMembers(),
        this.getListRolesInRoom(),
        this.getCircleAllMembers()
      ])
    },
    getMemberRoleName (data) {
      return typeof data === 'string' && appUtils.isJSON(data)
        ? JSON.parse(data)[this.selectedFeature]
        : typeof data === 'object'
          ? data[this.selectedFeature]
          : data
    },
    async setRoleData (circle_member_id, role_name) {
      const memberData = this.allMembers.find(
        mem => mem.id === circle_member_id
      )
      let one_hundred_ms_role = memberData?.one_hundred_ms_role
      one_hundred_ms_role =
        typeof one_hundred_ms_role === 'string' &&
        appUtils.isJSON(one_hundred_ms_role)
          ? one_hundred_ms_role
          : JSON.stringify(one_hundred_ms_role)
      const conditions = ['livestream', 'conference']
      const checkRole = conditions?.some(el =>
        one_hundred_ms_role?.includes(el)
      )
      const currentRoles = checkRole ? JSON.parse(one_hundred_ms_role) : {}
      const role = {
        ...currentRoles,
        [this.selectedFeature]: role_name
      }
      return role
    },
    async assignMember (circle_member_id, role_name) {
      this.loading = true

      const role = await this.setRoleData(circle_member_id, role_name)
      const params = {
        circle_member_id: circle_member_id,
        role: JSON.stringify(role)
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .assignConferenceRole(params)
        .then(r => {
          this.allMembers.find(
            mem => mem.id === circle_member_id
          ).one_hundred_ms_role = { ...role }

          this.loading = false

          if (
            this.circle?.created_by === this.$user?.id &&
            this.circle_member?.id === circle_member_id
          ) {
            this.circleMemberData = {
              ...this.circleMemberData,
              one_hundred_ms_role: appUtils.isJSON(
                r.data?.data?.one_hundred_ms_role
              )
                ? JSON.parse(r.data?.data?.one_hundred_ms_role)
                : r.data?.data?.one_hundred_ms_role
            }
          }
          this.$emit('refreshRole')
        })
    },
    unassignMember (circle_member_id) {
      this.assignMember(
        circle_member_id,
        this.selectedFeature === 'livestream' ||
          this.circle?.type === 2 ||
          (this.circle?.live_room_id && !this.conferenceRoomStatus)
          ? 'viewer'
          : 'guest'
      )
      this.selectedCircleMemberIds = this.selectedCircleMemberIds.filter(
        id => id !== circle_member_id
      )
    },
    toggleDropdownAddMember () {
      this.isActiveDropdownAddMember = !this.isActiveDropdownAddMember
    },
    onSubmit () {
      this.$emit('goToRoom')
    },
    async getCircleMembers () {
      let self = this
      if (!this.$route.params.id) return

      let params = {
        page: self.members.current_page,
        limit: self.members.per_page,
        status: 2
      }
      self.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getCircleMembers(this.$route.params.id, params)
        .then(r => {
          let members = r.data
          members.data.forEach(mem => {
            if (!mem.one_hundred_ms_role) {
              const role = {
                [this.selectedFeature]:
                  self.selectedFeature === 'livestream' ||
                  (self.circle?.live_room_id && !self.conferenceRoomStatus)
                    ? 'viewer'
                    : 'guest'
              }
              mem.one_hundred_ms_role = JSON.stringify(role)
            }
          })
          self.members = members
        })
        .catch(e => {
          console.log(e)
          self.loading = false

          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
        })
    },
    async getCircleAllMembers () {
      let self = this
      if (!self.$route.params.id || !self.circle?.id) return

      try {
        self.loading = true

        let params = {
          page: 1,
          limit: 1000,
          status: 2
        }

        if (self.circle && self.circle?.type === 2) {
          params = {
            status: 2,
            role: '1,2',
            page_num: 1,
            page_size: 1000,
            circle_id: self.circle?.id
          }

          await self.$rf
            .getRequest('DoctorRequest')
            .getLiveCircleMembers(params)
            .then(r => {
              let members = r.data
              // eslint-disable-next-line no-unused-expressions
              members?.data?.forEach(mem => {
                if (!mem.one_hundred_ms_role) {
                  const role = {
                    livestream: 'viewer'
                  }
                  mem.one_hundred_ms_role = JSON.stringify(role)
                }
                mem.user = mem?.user_info
              })

              // if (self.circle?.created_by === self.$user?.id) {
              //   // eslint-disable-next-line no-unused-expressions
              //   members?.data.push({
              //     ...self.circleMemberData,
              //     user: {
              //       ...self.circle?.seed?.doctor?.user
              //     }
              //   })
              // }
              self.allMembers = [...members?.data]
            })
            .catch(e => {
              console.log(e)
              self.$toast.open({
                message: self.$t('circle_feature.lbl_permission_msg'),
                type: 'warning'
              })
            })
            .finally(() => {
              self.selectedCircleMemberIds =
                self.allMembers &&
                self.allMembers
                  ?.filter(
                    mem =>
                      !['viewer', 'guest', null].includes(
                        mem?.one_hundred_ms_role[this.selectedFeature]
                      )
                  )
                  .map(mem => mem.id)
              self.loading = false
            })

          return
        }

        await self.$rf
          .getRequest('DoctorRequest')
          .getCircleMembers(self.$route.params.id, params)
          .then(r => {
            let members = r.data
            members.data.forEach(mem => {
              if (!mem.one_hundred_ms_role) {
                const role = {
                  [this.selectedFeature]:
                    self.selectedFeature === 'livestream' ||
                    (self.circle?.live_room_id && !self.conferenceRoomStatus)
                      ? 'viewer'
                      : 'guest'
                }
                mem.one_hundred_ms_role = JSON.stringify(role)
              }
            })
            self.allMembers = [...members?.data]
          })
          .catch(e => {
            console.log(e)
            self.$toast.open({
              message: self.$t('circle_feature.lbl_permission_msg'),
              type: 'warning'
            })
          })
          .finally(() => {
            self.selectedCircleMemberIds =
              self.allMembers &&
              self.allMembers
                ?.filter(mem => {
                  const isViewerOrGuest =
                    self.selectedFeature === 'livestream' ||
                    (self.circle?.live_room_id && !self.conferenceRoomStatus)
                      ? !['viewer', null].some(el =>
                          mem?.one_hundred_ms_role.includes(String(el))
                      )
                      : !['guest', null].some(el =>
                          mem?.one_hundred_ms_role.includes(String(el))
                      )
                  return isViewerOrGuest
                })
                .map(mem => mem.id)
            self.loading = false
          })
      } catch (error) {
        console.log(error)
      }
    },
    async getListRolesInRoom () {
      try {
        this.loading = true
        const type =
          this.selectedFeature ||
          (this.circle?.type === 2 ||
          (this.circle?.live_room_id && !this.conferenceRoomStatus)
            ? 'livestream'
            : 'conference')
        await this.$rf
          .getRequest('DoctorRequest')
          .getListRolesInRoom(type)
          .then(res => {
            const roleArr = Object.keys(res.data.data[0].roles)
            this.roles = roleArr
              .map(role => {
                // const role_name = role.charAt(0).toUpperCase() + role.slice(1)
                return {
                  ...res.data.data[0].roles[role],
                  // name_capitalized: role_name,
                  name_lang: this.$t(
                    `circle_feature.${appUtils.convertRolesName(role)}`
                  )
                }
              })
              .filter(
                role =>
                  role.name !== '__internal_recorder' &&
                  role.name !== 'hls-viewer'
              )
          })
          .catch(() => {
            this.loading = false
          })
          .finally(() => {
            // this.loading = false
          })
      } catch (error) {
        console.log(error)
      }
    },
    async handleChangePagination (data) {
      if (data.currentPage) this.members.current_page = data.currentPage
      if (data.pageSize) this.members.per_page = data.pageSize
      await this.getCircleMembers()
    },
    getUrlImage (path) {
      return appUtils.getImageURL(path)
    },
    openAssignSection () {
      this.isOpenAssignSection = true
      this.isActiveDropdownAddMember = true
      this.$nextTick(() => this.$refs.select_member?.focus())
    },
    async openModal (show) {
      if (show) {
        await this.prepare()
        // await this.getCircleMembers()
        // await this.getListRolesInRoom()
        // await this.getCircleAllMembers()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.member-wraper {
  width: 100%;
  padding: 20px 30px 40px 30px;
  min-height: 250px;
}
.save-btn {
  right: 10px;
  bottom: 10px;
  position: absolute;
}
.box-member {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 10px;
}

.img-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #20419b no-repeat center/cover;
}

.list-item-name {
  /* width: calc(100% - 60px); */
}

.list-item-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.dropdown-list-m {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.btn-plus {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px dashed #20419b;
  line-height: 20px;
  color: #20419b;
  padding: 8px;
}
.w-90 {
  width: 90% !important;
}
.w-30 {
  width: 30% !important;
}
.close {
  display: none;
}
.input-group:hover {
  position: relative;
  .close {
    display: inline-block;
    border-radius: 50% !important;
    background-color: #fef3f2;
    color: #b42318;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 24px;
  }
}
</style>


<style>
.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}
.checkbox-wrapper-1 [type="checkbox"].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox-wrapper-1 [type="checkbox"].substituted + label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset,
    0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
      no-repeat center,
    white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}
.checkbox-wrapper-1 [type="checkbox"].substituted:enabled:active + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:enabled
  + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}
.checkbox-wrapper-1 [type="checkbox"].substituted:checked + label:before {
  background-color: #3b99fc;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled
  + label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55),
    0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:focus:active + label:before,
.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55),
    0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:disabled + label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type="checkbox"].substituted.dark + label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled
  + label:active:before {
  background-color: #444;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
}
.checkbox-wrapper-1 [type="checkbox"].substituted.dark:checked + label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled
  + label:active:before {
  background-color: #c68035;
}
</style>

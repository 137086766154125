<template>
  <div>
    <form @submit.prevent="onSubmit" autocomplete="off">
      <div class="card-body">
        <div class="mb-3">
          <label class="form-label required">{{
            $t("workspace_setting.lbl_we_name")[$orgModel]
          }}</label>
          <input
            type="text"
            class="form-control"
            v-model="form.name"
            :placeholder="$t('workspace_setting.lbl_ws_name_plh')[$orgModel]"
          />
        </div>
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label class="form-label required">Email</label>
              <input
                type="email"
                class="form-control"
                :disabled="isEdit"
                v-model="form.username"
                placeholder="Nhập email..."
              />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label class="form-label required">Mật khẩu</label>
              <input
                type="password"
                class="form-control"
                v-model="form.password"
                placeholder="Nhập mật khẩu..."
                autocomplete="new-password"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label mb-4">{{
              $t("form.title.open_time")
            }}</label>
            <div class="mb-3">
              <input
                type="time"
                class="form-control"
                v-model="form.open_time"
                placeholder="Nhập giờ mở cửa..."
              />
            </div>
          </div>
          <div class="mb-3 col-6 col-md-6">
            <label class="form-label mb-4">{{
              $t("form.title.close_time")
            }}</label>
            <div class="mb-3">
              <input
                type="time"
                class="form-control"
                v-model="form.close_time"
                placeholder="Nhập giờ đóng cửa..."
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label required">Số Điện Thoại</label>
              <input
                type="text"
                class="form-control"
                v-model="form.phone"
                placeholder="Nhập số điện thoại..."
              />
            </div>
            <div class="mb-3">
              <label class="form-label required">Địa Chỉ</label>
              <input
                type="text"
                class="form-control"
                v-model="form.address"
                :placeholder="$t('workspace_setting.lbl_enter_workspace_address')[$orgModel]"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Mô tả</label>
              <textarea
                class="form-control"
                v-model="form.desc_full"
                :placeholder="$t('workspace_setting.lbl_enter_workspace_description')[$orgModel]"
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col-md-4">
              <label class="form-label">Ảnh Đại Diện</label>
              <div class="form-file mb-1">
                <input
                  type="file"
                  class="form-control"
                  accept="image/*"
                  @change="onSelectedThumbFile($event)"
                />
              </div>
              <img
                v-if="thumb_file || clinic_avatar"
                :src="thumb_file ? getBase64URL(thumb_file) : clinic_avatar"
                alt=""
              />
            </div>
            <div class="col-md-4">
              <label class="form-label">Banner Web</label>
              <div class="form-file mb-1">
                <input
                  type="file"
                  class="form-control"
                  accept="image/*"
                  @change="onSelectedWebThumbFile($event)"
                />
              </div>
              <img
                v-if="thumb_file_web || banner_web"
                :src="
                  thumb_file_web ? getBase64URL(thumb_file_web) : banner_web
                "
                alt=""
              />
            </div>
            <div class="col-md-4">
              <label class="form-label">Banner Mobile App</label>
              <div class="form-file mb-1">
                <input
                  type="file"
                  class="form-control"
                  accept="image/*"
                  @change="onSelectedAppThumbFile($event)"
                />
              </div>
              <img
                v-if="thumb_file_app || banner_app"
                :src="
                  thumb_file_app ? getBase64URL(thumb_file_app) : banner_app
                "
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col-md-4">
              <label class="form-label">Trạng Thái</label>
              <div>
                <label class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="form.status"
                    value="0"
                  />
                  <span class="form-check-label text-danger">Inactive</span>
                </label>
                <label class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="form.status"
                    value="1"
                  />
                  <span class="form-check-label text-success">Active</span>
                </label>
              </div>
            </div>
            <div class="col-md-4">
              <label class="form-label">Hiển thị với bệnh nhân</label>
              <div>
                <label class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="form.show_for_patient"
                    value="0"
                  />
                  <span class="form-check-label text-danger">Ẩn</span>
                </label>
                <label class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="form.show_for_patient"
                    value="1"
                  />
                  <span class="form-check-label text-success">Hiện</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-footer text-center">
          <button
            type="submit"
            class="btn bg-pri bd-pri text-white"
            :disabled="loading"
          >
            <span
              class="spinner-border spinner-border-sm mr-2"
              role="status"
              v-if="loading"
            ></span>
            {{ isEdit ? "Cập Nhật" : "Thêm Mới" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import appUtils from '../utils/appUtils.js'
export default {
  name: 'ClinicForm',
  props: {
    inputs: Object,
    isEdit: Boolean,
    loading: Boolean
  },
  data () {
    return {
      form: {
        name: '',
        username: '',
        password: '',
        phone: '',
        address: '',
        thumb: '',
        status: 0,
        show_for_patient: 0,
        desc_full: '',
        open_time: '',
        close_time: ''
      },
      thumb_file: '',
      thumb_file_web: '',
      thumb_file_app: '',
      clinic_avatar: '',
      banner_web: '',
      banner_app: ''
    }
  },
  mounted () {
    let self = this

    if (self.inputs) {
      self.form = self.inputs
      if (self.inputs && self.inputs.open_time) {
        console.log(self.inputs.open_time)
        self.form.open_time = self.getTime(self.inputs.open_time)
      }
      if (self.inputs && self.inputs.close_time) {
        self.form.close_time = self.getTime(self.inputs.close_time)
      }
      self.form.username = self.inputs.user ? self.inputs.user.username : ''
      self.form.password = ''
      if (self.inputs.avatar) {
        self.clinic_avatar = appUtils.getImageURL(self.inputs.avatar)
      }
      if (self.inputs.banner_app) {
        self.banner_app = appUtils.getImageURL(self.inputs.banner_app)
      }
      if (self.inputs.banner_web) {
        self.banner_web = appUtils.getImageURL(self.inputs.banner_web)
      }
    }
  },
  methods: {
    onSubmit () {
      let self = this
      let data = self.form
      data.avatar = self.thumb_file
      data.banner_web = self.thumb_file_web
      data.banner_app = self.thumb_file_app
      var formData = new FormData()

      for (var key in data) {
        if (key !== 'user') {
          formData.append(key, data[key])
        }
      }

      return self.$emit('onSubmit', formData)
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    onSelectedThumbFile (event) {
      this.thumb_file = event.target.files[0]
    },
    onSelectedWebThumbFile (event) {
      this.thumb_file_web = event.target.files[0]
    },
    onSelectedAppThumbFile (event) {
      this.thumb_file_app = event.target.files[0]
    },
    getTime (time) {
      let date = '2020-11-26'
      return window.moment(date + ' ' + time).format('HH:mm')
    }
  }
}
</script>
<style lang="css" scoped="true">
.form-control {
  background-color: white !important;
}
</style>